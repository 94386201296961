<template>
    <div class="max-w-lg mx-auto p-4 bg-white mt-8">
        <form class="text-left" @submit.prevent="submitForm()">
            <div class="w-full flex flex-col">
                <label for="">Question Type</label>
                <Select class="flex border focus:outline-none p-2" v-model="form.type">
                    <option value="">Select</option>
                    <option value="0">Patient</option>
                    <option value="1">Mentor</option>
                </Select>
            </div>
            <div>
                <label for="">Question</label>
                <div class="grid grid-cols-5 gap-4">
                    <div class="col-span-full">
                        <div class="grid grid-cols-5 mb-2 mk"  v-for="(question, index) in form.questions" :key="index">
                            <Textarea
                                class="col-span-4 py-2 p-2 focus:outline-none border"
                                v-model="form.questions[index]"
                            />
                            <button class="col-span-1 text-red-500" @click="remove(index)">
                                &#9587;
                            </button>
                        </div>
                    </div>
                    <Btn type="button" @click="addQuestions()"> + Add</Btn>
                </div>
            </div>
        <div class="mt-2">
            <Btn class="" type="submit">Submit</Btn>
        </div>
        </form>
         
    </div>
</template>
<script>
import Select from '../../../components/select.vue'
import Input from '../../../components/input-new.vue'
import Textarea from '../../../components/textarea.vue'
import Btn from '../../../components/btn.vue'
export default {
    components: {
        Select,
        Input,
        Textarea,
        Btn
    },
    data(){ 
        return {
            form:{
                questions: [''],
                type:''
            }
        }
    },
    methods:{
        addQuestions() {
            this.form.questions.push('');
        },
        
        remove(index){
            this.form.questions.splice(index, 1);
        },
        submitForm(){
            this.$store.dispatch('adminFeedback/feedbackQuestion',{
                data: this.form,
            }).then( () => {
                this.$toast.success(`Successfully created !!!`,{
                   position: "top",
                   duration:3000
                });
                this.$router.replace({
                    path:'/admin/feedbacks'
                })
           });
        }

    }
}
</script>